import * as React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";

import OurNewsletters from "../utils/newsletters";

const Newsletters = () => {
  return (
    <Layout>
      <SEO title="Newsletters | Visit Tunisia Project" />
      <main role="main">
        <div className="container-fluid">
          <div className="region region-content">
            <div data-drupal-messages-fallback className="hidden" />
            <div
              id="block-jobs-front-content"
              className="block block-system block-system-main-block"
            >
              <div className="views-element-container">
                <section className="newsletter-list">
                  <div className="container">
                    <h2>Newsletters</h2>
                  </div>

                  <div className="container">
                    <div className="list-news d-flex flex-lg-row flex-md-row flex-column flex-wrap grid">
                      {OurNewsletters.slice(0)
                        .reverse()
                        .map((newsletter, index) => (
                          <div className="card newsletter-item views-row">
                            <a href={newsletter.file} target="_blank">
                              <img
                                src={newsletter.image}
                                width={1996}
                                height={1248}
                                alt="Domestic Tourism Survey"
                                typeof="Image"
                                className="card-img-top"
                              />
                            </a>
                            <div className="card-body">
                              <a
                                href={newsletter.file}
                                className="btn btn-primary"
                                target="_blank"
                              >
                                Show more
                              </a>
                              <h3>
                                <span>Newsletter </span>#
                                {OurNewsletters.length - index}&nbsp;
                                {newsletter.month}
                              </h3>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default Newsletters;
